import { AxiosResponse } from 'axios'
import apiClient from './apiClient'
import type { Browser, BrowserType } from '../objects/Browser'

let getBrowserTypeLoading: boolean = false

let currentBrowserPromise: Promise<Browser> | null = null

export const get = async (browser_id: string): Promise<AxiosResponse<any, any>> => {
    return apiClient.get('/browsers/' + browser_id)
}

export const getBrowser = async (browserId: string): Promise<Browser> => {
    if (currentBrowserPromise) {
        return currentBrowserPromise
    }

    try {
        currentBrowserPromise = (async () => {
            const response = await get(browserId)
            return response.data
        })()

        const result = await currentBrowserPromise
        return result
    } catch (e) {
        console.error(e)
        throw e
    } finally {
        currentBrowserPromise = null
    }
}

export const getBrowserType = async (): Promise<BrowserType | undefined> => {
    if (getBrowserTypeLoading) return

    try {
        getBrowserTypeLoading = true
        const browser = await getBrowser(window.browser_id)
        return browser?.browser_type
    } catch (e) {
        console.error(e)
    } finally {
        getBrowserTypeLoading = false
    }
}

export default {
    get,
    getBrowser,
    getBrowserType,
}
