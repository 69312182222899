import Vue from 'vue'
import UserEvent from '../../objects/UserEvent'
import { initializeStatsig, isUserOnMobile } from '../../mixins/StatsigHelper'
import UserApi from '../../apis/UserApi'

export const namespaced = true

export const state = {
    vue: null,
    premium_marker_color: null,
    default_widget_dropdown: 'instruction',
    free_plan_layout: 'top-center-section',
    paywall_type: 'checkout',
    header_styles: false,
    loadingMakePrintable: true,
    worksheetTitle: 'Log in to publish this worksheet',
    bingoTitle: 'Log in to publish these bingo cards',
    flashcardTitle: 'Log in to publish these flashcards',
    loading: true,
    blank_title: false,
    defaultTitle: '',
    abGroup: 0,
    fillInTheBlankInstructionText: "Write <b>full sentence</b>. Don't skip any words.",
    fillInTheBlankPlaceholderText: 'Write your complete sentence.',
    flashcardDefaultTitle: '',
    worksheetHeaderAndTitleLabelCombination: {},
    stickerButtonDefaultColorAndText: {
        color: 'primary',
        text: 'Add Sticker',
        loading: true,
    },
    stickyHeader: false,
}

export const mutations = {
    SET_VALUE(state, values) {
        Object.keys(values).forEach((value) => {
            state[value] = values[value]
        })
    },
    SET_AB_GROUP(state, group) {
        state.abGroup = group
    },
}

export const actions = {
    setValue({ commit }, value) {
        commit('SET_VALUE', value)
    },
    async initialize({ dispatch, rootGetters }, payload = {}) {
        try {
            state.loading = true
            await initializeStatsig()

            if (payload.completed) payload.completed()

            dispatch('logStatsigEvent', { event: UserEvent.USER_VISIT })

            const isAuthtenticatedUser = rootGetters['user/isLoggedIn']
            const userHasTrialed = rootGetters['user/hasTrialed']
            const userIsOnFreePlan = rootGetters['user/isOnFreePlan']

            if (
                window?.new_browser &&
                !isAuthtenticatedUser &&
                (!document.referrer ||
                    !document.referrer.includes('myworksheetmaker') ||
                    !document.referrer.includes('brightsprout'))
            ) {
                // TODO: deprecate this event.
                dispatch('logStatsigEvent', { event: UserEvent.NEW_USER_VISIT })
            } else if (isAuthtenticatedUser) {
                await dispatch('loadAbGroup')
            }

            dispatch('loadAbTests')
            dispatch('loadHeaderStylesAb')
            dispatch('loadBlankTitleTests')

            // Load experiments that are only for free plan users who have trialed (cardless).
            // TODO: Refactor this to use a more robust solution.
            if (isAuthtenticatedUser && userHasTrialed && userIsOnFreePlan) {
                await dispatch('user/getPayments', null, { root: true })
                await dispatch('cards/getCards', null, { root: true })

                const userHasMadePayments = rootGetters['user/getPayments']
                const userHasDefaultCard = !!rootGetters['cards/defaultCard']

                if (userHasMadePayments.length > 0 || userHasDefaultCard) {
                    return
                }

                dispatch('loadFreeTrialExpiredExperiments')
            }
        } catch (error) {
            console.error('Error initializing Statsig', error)
        } finally {
            state.loading = false
        }
    },
    logStatsigEvent({ _ }, { event, value, payload }) {
        Vue.prototype.$logTrackingEvent(event, value, false, false, payload)
    },
    loadAbTests({ commit }) {
        commit('SET_VALUE', {
            premium_marker_color: '#715dd6',
            default_widget_dropdown: window.statsig
                ?.getExperiment('default_widget_dropdown')
                .get('default_widget', 'instruction'),
        })
    },
    loadFreeTrialExpiredExperiments({ commit }) {
        commit('SET_VALUE', {
            paywall_type: window.statsig?.getExperiment('paywall_vs_checkout').get('paywall', 'checkout'),
        })
    },
    loadHeaderStylesAb({ commit }) {
        commit('SET_VALUE', {
            header_styles: window.statsig
                ?.getExperiment('header_styles_behind_a_feature_button')
                .get('feature_enabled', false),
        })
    },
    loadBlankTitleTests({ rootGetters, commit }) {
        let entityType = `${rootGetters['document/entityType']}`
        if (!entityType || entityType === 'worksheet') return

        let experiment = ''
        if (entityType === 'flashcard') {
            experiment = 'flashcards_default_title'
        } else if (entityType === 'bingo') {
            experiment = 'bingo_default_title'
        }

        commit('SET_VALUE', {
            blank_title: window.statsig?.getExperiment(`${experiment}`).get('blank_title', false),
        })
    },
    loadFreePlanLayoutTest({ state, rootGetters }) {
        let entityType = `${rootGetters['document/entityType']}`
        if (!entityType) return

        // let freePlanLayout = !window.user?.trialed ? window.statsig?.getExperiment(`${entityType}_free_plan_layout`).get('layout', state.free_plan_layout) : state.free_plan_layout
        // TODO: Investigate why this is set like this. It doesn't do anything.
        let freePlanLayout = state.free_plan_layout
    },
    loadDomainCreateAccountHeaderTests({ commit }) {
        switch (window.doc.entity_type) {
            case 'flashcard':
                commit('SET_VALUE', {
                    flashcardTitle: window.statsig
                        ?.getExperiment('flashcards_create_account_header_text')
                        .get('title', 'Log in to publish these flashcardscards'),
                })
                break
            case 'bingo':
                commit('SET_VALUE', {
                    bingoTitle: window.statsig
                        ?.getExperiment('bingo_create_account_header_text')
                        .get('title', 'Log in to publish these bingo cards'),
                })
                break
            case 'worksheet':
                commit('SET_VALUE', {
                    worksheetTitle: window.statsig
                        ?.getExperiment('worksheets_create_account_header_text')
                        .get('title', 'Log in to publish this worksheet'),
                })
                break

            default:
                break
        }
    },
    async loadAbGroup({ commit }) {
        try {
            const response = await UserApi.getAbGroup()
            commit('SET_AB_GROUP', response.group)
        } catch (error) {
            console.error('Error getting AB group', error)
        }
    },
    loadFillInTheBlankTests({ commit, rootGetters }) {
        if (!window.statsig) {
            return
        }

        const entityType = rootGetters['document/entityType']

        if (entityType !== 'worksheet') return

        commit('SET_VALUE', {
            fillInTheBlankInstructionText: window.statsig
                ?.getExperiment('fill_in_the_blank_instruction_text')
                .get('text', state.fillInTheBlankInstructionText),
        })

        commit('SET_VALUE', {
            fillInTheBlankPlaceholderText: window.statsig
                ?.getExperiment('fill_in_the_blank_placholder_text')
                .get('text', state.fillInTheBlankPlaceholderText),
        })
    },
    loadWorksheetHeaderAndTitleLabelCombinationTests({ commit }) {
        const testValues = [
            {
                id: 1,
                header: 'Worksheet Header',
                title: 'Worksheet Title',
            },
            {
                id: 2,
                header: 'Worksheet Header',
                title: 'Title',
            },
            {
                id: 3,
                header: 'Header',
                title: 'Worksheet Title',
            },
            {
                id: 4,
                header: 'Header',
                title: 'Title',
            },
        ]

        const experiment = window.statsig
            ?.getExperiment('worksheet_header_and_title_label_test')
            .get('titleHeaderLabelCombinationId', 1)

        const testValue = testValues.find((value) => value.id === experiment)

        commit('SET_VALUE', {
            worksheetHeaderAndTitleLabelCombination: testValue,
        })
    },
    loadFlashcardsDefaultTitleOptionTests({ commit }) {
        commit('SET_VALUE', {
            flashcardDefaultTitle: window.statsig
                ?.getExperiment('flashcards_default_title_option')
                .get('title', 'My New Flashcards'),
        })
    },
    async loadStickerButtonColorAndTextTests({ commit, state, rootGetters }) {
        // Worksheets only.
        let entityType = `${rootGetters['document/entityType']}`
        if (!entityType || entityType !== 'worksheet') {
            commit('SET_VALUE', {
                stickerButtonDefaultColorAndText: { ...state.stickerButtonDefaultColorAndText, loading: false },
            })
            return
        }

        // Do not expose experiment to "old" users or mobile users.
        if (!window.new_browser || (await isUserOnMobile())) {
            commit('SET_VALUE', {
                stickerButtonDefaultColorAndText: { ...state.stickerButtonDefaultColorAndText, loading: false },
            })
            return
        }

        const testVariant = window.statsig?.getExperiment('sticker_button_color_and_text')

        let testVariantValue
        if (!testVariant || !testVariant.value || !testVariant.value.color) {
            testVariantValue = state.stickerButtonDefaultColorAndText
        } else {
            testVariantValue = testVariant.value
        }

        commit('SET_VALUE', {
            stickerButtonDefaultColorAndText: { ...testVariantValue, loading: false },
        })
    },
    async loadStickHeaderTests({ commit, state, rootGetters }) {
        // Do not expose experiment to "old" users or mobile users.
        if (!window.new_browser || (await isUserOnMobile())) return

        const testVariant = window.statsig?.getExperiment('sticky_header')

        let testVariantValue
        if (!testVariant || !testVariant.value) {
            testVariantValue = state.stickyHeader
        } else {
            testVariantValue = testVariant.value.isSticky
        }

        commit('SET_VALUE', {
            stickyHeader: testVariantValue,
        })
    },
}

export const getters = {
    abGroup: (state) => state.abGroup,
    premiumMarkerColor: (state) => state.premium_marker_color,
    defaultSelectedWidget: (state) => state.default_widget_dropdown,
    freePlanLayout: (state) => state.free_plan_layout,
    paywallType: (state) => state.paywall_type,
    headerStyles: (state) => state.header_styles,
    loadingAbAction: (state) => state.loadingMakePrintable,
    worksheetTitle: (state) => state.worksheetTitle,
    bingoTitle: (state) => state.bingoTitle,
    flashcardTitle: (state) => state.flashcardTitle,
    blankTitle: (state) => state.blank_title,
    loading: (state) => state.loading,
    fillInTheBlankInstructionText: (state) => state.fillInTheBlankInstructionText,
    fillInTheBlankPlaceholderText: (state) => state.fillInTheBlankPlaceholderText,
    defaultTitle: (state) => state.defaultTitle,
    worksheetHeaderAndTitleLabelCombination: (state) => state.worksheetHeaderAndTitleLabelCombination,
    flashcardDefaultTitle: (state) => state.flashcardDefaultTitle,
    stickerButtonDefaultColorAndText: (state) => state.stickerButtonDefaultColorAndText,
    stickyHeader: (state) => state.stickyHeader,
}
