import { defineComponent } from 'vue'
import Statsig from 'statsig-js'
import BrowsersApi from '../apis/BrowsersApi'
import UserEvent from '../objects/UserEvent'

export default defineComponent({
    data() {
        return {
            logPersistInterval: null,
        }
    },
    mounted() {
        this.startLogPersistInterval()
    },
    beforeUnmount() {
        this.stopLogPersistInterval()
    },
    methods: {
        startLogPersistInterval() {
            this.stopLogPersistInterval()
            this.logPersistInterval = window.setInterval(this.logPersistTime, 1000)
        },
        stopLogPersistInterval() {
            if (this.logPersistInterval) {
                window.clearInterval(this.logPersistInterval)
                this.logPersistInterval = null
            }
        },
        async logPersistTime() {
            if (!window.browser_id) return

            let landingInfo = { [window.browser_id]: { landingInMili: Date.now(), persistInSec: 0 } }
            try {
                const storedInfo = localStorage.getItem('MWM_LANDING_INFO')
                if (storedInfo) {
                    landingInfo = JSON.parse(storedInfo)
                }
            } catch (error) {
                landingInfo = { [window.browser_id]: { landingInMili: Date.now(), persistInSec: 0 } }
            }

            let landingInMili = landingInfo[window.browser_id]?.landingInMili || Date.now()
            let persistInSec = landingInfo[window.browser_id]?.persistInSec || 0

            if (new Date(landingInMili).getDate() !== new Date().getDate()) {
                landingInMili = Date.now()
                persistInSec = 0
            }

            landingInfo[window.browser_id] = { landingInMili, persistInSec }
            const durationInSec = Math.floor((Date.now() - landingInMili) / 1000)
            if (
                (persistInSec < 30 && durationInSec >= 30) ||
                (persistInSec < 120 && durationInSec >= 120) ||
                (persistInSec < 300 && durationInSec >= 300) ||
                (persistInSec < 600 && durationInSec >= 600) ||
                (persistInSec < 900 && durationInSec >= 900) ||
                (persistInSec < 1800 && durationInSec >= 1800)
            ) {
                await this.postPersistTime(durationInSec)
                landingInfo[window.browser_id].persistInSec = durationInSec
            }

            try {
                localStorage.setItem('MWM_LANDING_INFO', JSON.stringify(landingInfo))
            } catch (error) {
                console.error('Error saving landing info to localStorage:', error)
            }
        },
        async postPersistTime(durationInSec) {
            await this.$logTrackingEvent(UserEvent.PERSIST, undefined, false, false, { duration: durationInSec })
        },
        async logNavigation(page) {
            await this.$logTrackingEvent(UserEvent.NAVIGATE, undefined, false, false, { page })
        },
        async logNavigateForPayment(page) {
            if (['worksheet', 'bingo', 'flashcard'].includes(page)) {
                page = 'make a ' + page
            } else if (page === 'account') {
                page = 'my account'
            }
            await this.$logTrackingEvent(UserEvent.NAVIGATE_FOR_PAYMENT, undefined, false, false, { page })
        },
        async logChoosePlan(period, trial, planType, page) {
            await this.$logTrackingEvent(UserEvent.CHOOSE_PLAN, page, false, false, { period, trial, planType, page })
        },
        async logPaymentSubmittion(period, trial, planType, page) {
            await this.$logTrackingEvent(UserEvent.PAYMENT_SUBMISSION, undefined, false, false, {
                period,
                trial,
                planType,
                page,
            })
        },
        async logPaymentSuccess(period, trial, planType, page) {
            await this.$logTrackingEvent(UserEvent.PAYMENT_SUCCESS, undefined, false, false, {
                period,
                trial,
                planType,
                page,
            })
        },
        async logFreeSubscriptionSignup(page) {
            await this.$logTrackingEvent(UserEvent.FREE_SUBSCRIPTION_SIGNUP, undefined, false, false, { page })
        },
        async logNewSignUp(page = 'account') {
            await this.$logTrackingEvent(UserEvent.NEW_USER_SIGNUP, undefined, false, false, {
                page,
                authMethod: 'manual',
                authType: 'create account',
            })
        },
        async logClickMakePrintable(page) {
            await this.$logTrackingEvent(UserEvent.PUBLISH_BUTTON_CLICK, undefined, false, false, { page })
        },
        async getFreePlanLayout() {
            return await this.$store.dispatch('abtests/loadFreePlanLayoutTest')
        },
        async logSocialAuthAttempt(authType = 'login') {
            await this.$logTrackingEvent(UserEvent.AUTHENTICATION_ATTEMPTED, undefined, false, false, {
                authMethod: 'google',
                authType,
            })
        },
    },
})

export const initializeStatsig = async () => {
    if (!window.browser_id) {
        return Promise.reject('No browser id')
    }

    window.statsig = Statsig.default || Statsig
    if (!window.statsig?.initialize) {
        return Promise.reject('Statsig not initialized')
    }

    const statsigUser = await getStatsigUserPayload()

    if (!statsigUser) {
        return Promise.reject('No statsig user')
    }

    try {
        await window.statsig?.initialize(window.statsig_client_key, statsigUser, {
            environment: {
                tier: window.env === 'local' ? 'development' : window.env,
            },
        })

        return Promise.resolve()
    } catch (error) {
        return Promise.reject(error)
    }
}

export const getEntityStatsigValue = (entity) => {
    let title = 'Worksheet'
    switch (entity) {
        case 'bingo':
            title = 'Bingo Cards'
            break
        case 'flashcard':
            title = 'Flashcards'
            break
        default:
            break
    }

    return title
}

export const getBrowserUuid = async () => {
    let uuid = localStorage.getItem('CUSTOM_AB_STABLE_ID')

    if (!uuid) {
        try {
            const browser = await BrowsersApi.getBrowser(window.browser_id)
            uuid = browser.uuid
            localStorage.setItem('CUSTOM_AB_STABLE_ID', uuid)
        } catch (e) {
            console.error(e)
        }
    }

    return uuid
}

export const getCustomStableID = async () => {
    let abStableID
    if (window.user?.custom_ab_stable_id) {
        abStableID = window.user.custom_ab_stable_id
    } else {
        abStableID = localStorage.getItem('CUSTOM_AB_STABLE_ID')
        if (!abStableID) {
            abStableID = await getBrowserUuid()
        }
    }

    return abStableID
}

export const getStatsigUserPayload = async (user) => {
    const uuid = user?.uuid || window.user?.uuid || (await getBrowserUuid())

    if (!uuid) {
        return Promise.reject('No user id')
    }

    const abStableID =
        user?.custom_ab_stable_id || (await getCustomStableID()) || localStorage.getItem('CUSTOM_AB_STABLE_ID')

    return {
        userID: uuid,
        custom: {
            new_user: isEligibleNewUser(),
        },
        customIDs: {
            systemID: abStableID,
        },
        email: user?.email || window.user?.email,
    }
}

export const isEligibleNewUser = () => {
    if (window?.new_browser) return true
    if (!window.user || !window.user?.created_at) return false
    //Temporary check for new users. to be replaced with more experiment specific evaluation
    return new Date(window.user?.created_at) >= new Date('2023-12-22')
}

/**
 * Check if the user is eligible for feature gate
 *
 * @param {string} featureName - name of the feature gate
 * @returns boolean
 */
export const checkStatsigFeatureGate = async (featureName) => {
    if (!window.statsig || !featureName) {
        return Promise.reject('No statsig or feature name')
    }

    const statsigUser = await getStatsigUserPayload()

    if (!statsigUser) {
        return Promise.reject('No statsig user')
    }

    const isFeatureGated = window.statsig?.checkGate(featureName, statsigUser)

    return isFeatureGated
}

/**
 * Check if the user is on mobile
 *
 * @return boolean
 */
export const isUserOnMobile = async () => {
    const browserType = await BrowsersApi.getBrowserType()
    return browserType === 'mobile'
}
