export interface EventDefinition {
    name: string
    providers: ('internal' | 'google-analytics' | 'statsig')[]
}

export const UserEvent = {
    // When a user adds an activity to a worksheet
    ADD_ACTIVITY: {
        name: 'add-activity',
        providers: ['internal', 'google-analytics'],
    },

    // When a user sees the cancel subscription overlay
    CANCEL_SUBSCRIPTION_OVERLAY_SEEN: {
        name: 'cancel-subscription-overlay-seen',
        providers: ['internal', 'google-analytics'],
    },

    // When a user clicks the 'Start Over' button
    CLEAR_ALL_ACTIVITIES: {
        name: 'clear-all-activities',
        providers: ['internal', 'google-analytics'],
    },

    // When a user clicks the 'click to copy' button/link
    COPY_DOCUMENT_URL_CLICKED: {
        name: 'copy-document-url-clicked',
        providers: ['internal', 'google-analytics'],
    },

    // When selecting a document type after clicking 'Make Your Own' on the Browse page
    DOCUMENT_TYPE_SELECTED: {
        name: 'document-type-selected',
        providers: ['internal', 'google-analytics'],
    },

    // When a user clicks the 'explore more options' link on the 'My Account/Profile' page
    EXPLORE_MORE_OPTIONS_CLICKED: {
        name: 'explore-more-options-clicked',
        providers: ['internal', 'google-analytics'],
    },

    // When selecting to continue with the free version
    FREE_VERSION_SELECTED: {
        name: 'free_version_selected',
        providers: ['internal', 'google-analytics'],
    },

    // When the user has successfully selected the free version
    FREE_VERSION_SUCCEEDED: {
        name: 'free_version_succeded',
        providers: ['internal', 'google-analytics'],
    },

    // When a document has been published and the user is about to be redirected to the published document page
    LEAVING: {
        name: 'leaving',
        providers: ['internal', 'google-analytics'],
    },

    // When an unauthenticated user attempts to set a rating on a published document and is show the log in modal
    LOGIN_VIEW_WORKSHEET_RATING: {
        name: 'log-in-view-worksheet-rating',
        providers: ['internal', 'google-analytics'],
    },

    // When a user has successfully logged in either with Google or standard
    LOGIN_SUCCEEDED: {
        name: 'login-succeeded',
        providers: ['internal', 'google-analytics'],
    },

    // When a user cicks the 'Login' tab on the Sign Up/Login modal
    LOGIN_WORKSHEET: {
        name: 'login-worksheet',
        providers: ['internal', 'google-analytics'],
    },

    // When a user clicks the 'Log in' button in the top navigation bar
    LOGIN_WORKSHEET_TOOLBAR: {
        name: 'login-worksheet-toolbar',
        providers: ['internal', 'google-analytics'],
    },

    // When a user sees the onboarding complete overlay after a cardless free trial sign up
    ONBOARDING_COMPLETE_OVERLAY_SEEN: {
        name: 'onboarding-complete-overlay-seen',
        providers: ['internal', 'google-analytics'],
    },

    // When a user clicks the 'Finish + Print' button in the onboarding complete overlay
    ONBOARDING_COMPLETE_PUBLISH_BUTTON_CLICKED: {
        name: 'onboarding-complete-publish-button-clicked',
        providers: ['internal', 'google-analytics'],
    },

    // When a user sees the one-click upgrade overlay
    ONE_CLICK_UPGRADE_OVERLAY_SEEN: {
        name: 'one-click-upgrade-overlay-seen',
        providers: ['internal', 'google-analytics'],
    },

    // When a user views a page
    PAGE_VIEW: {
        name: 'page-view',
        providers: ['internal', 'google-analytics'],
    },

    // When a user changes tabs or minimizes the browser window, etc.
    PAGE_VISIBILITY_CHANGE: {
        name: 'page-visibility-change',
        providers: ['internal', 'google-analytics'],
    },

    // When a user sees the payment details overlay in the checkout modal (when the component mounts)
    PAYMENT_DETAILS_OVERLAY_SEEN: {
        name: 'payment-details-overlay-seen',
        providers: ['internal', 'google-analytics'],
    },

    // When a user payment attempt fails
    PAYMENT_FAILED: {
        name: 'payment-failed',
        providers: ['internal', 'google-analytics'],
    },

    // When a user attempts a payment by submitting their payment information
    PAYMENT_SUBMITTED: {
        name: 'payment-submitted',
        providers: ['internal', 'google-analytics'],
    },

    // When a user's payment attempt succeeds
    PAYMENT_SUCCEEDED: {
        name: 'payment-succeeded',
        providers: ['internal', 'google-analytics'],
    },

    // When a user clicks either Generate PDF or Download
    PDF_DOWNLOAD: {
        name: 'pdf_download',
        providers: ['internal', 'google-analytics'],
    },

    // When the user sees the plan selection overlay in the checkout modal (whe the component mounts)
    PLAN_SELECTION_OVERLAY_SEEN: {
        name: 'plan_selection_overlay_seen',
        providers: ['internal', 'google-analytics'],
    },

    // When a user clicks the 'Go Premium!' button in the Premium Marker popover
    PREMIUM_MARKER_CLICKED: {
        name: 'premium-marker-clicked',
        providers: ['internal', 'google-analytics'],
    },

    PREMIUM_WALL_SEEN: {
        name: 'premium-wall-seen',
        providers: ['internal', 'google-analytics'],
    },

    // When the user clicks the 'Print' button on a published document
    PRINT: {
        name: 'print',
        providers: ['internal', 'google-analytics'],
    },

    // When the user keys in the print shortcut e.g. Ctrl+p
    PRINT_SHORTCUT: {
        name: 'print_shortcut',
        providers: ['internal', 'google-analytics'],
    },

    // When a user sees the publish overlay
    PUBLISH_OVERLAY_SEEN: {
        name: 'publish-overlay-seen',
        providers: ['internal', 'google-analytics'],
    },

    // When a user clicks the 'Publish/Make Printable' button
    PUBLISH_ATTEMPTED: {
        name: 'publish-attempted',
        providers: ['internal', 'google-analytics'],
    },

    // When a user clicks the 'Publish/Make Printable' button and the document fails to publish
    PUBLISH_FAILED: {
        name: 'publish-failed',
        providers: ['internal', 'google-analytics'],
    },

    // When a user clicks the 'Publish/Make Printable' button and the document is successfully published
    PUBLISH_SUCCEEDED: {
        name: 'publish-succeeded',
        providers: ['internal', 'google-analytics'],
    },

    // When a user removes an activity from a worksheet
    REMOVE_ACTIVITY: {
        name: 'remove-activity',
        providers: ['internal', 'google-analytics'],
    },

    // When a user clicks the Save button
    SAVE_BUTTON_CLICKED: {
        name: 'save-button-clicked', // previously 'sign-up-open'
        providers: ['internal', 'google-analytics'],
    },

    // When a user sees the user communication overlay
    SEEN_USER_COMMUNICATION_OVERLAY: {
        name: 'seen-user-communication-overlay',
        providers: ['internal', 'google-analytics'],
    },

    // When the user sees the user role selection overlay (Teacher, Homeschooler, etc.)
    SEEN_USER_ROLE_OVERLAY: {
        name: 'user-role-overlay-seen',
        providers: ['internal', 'google-analytics'],
    },

    // Not used
    SIGN_UP_SAVE_DOCUMENT: {
        name: 'sign-up-save-document',
        providers: ['internal', 'google-analytics'],
    },

    // Not used
    SIGN_UP_SAVE_WORKSHEET: {
        name: 'sign-up-save-worksheet',
        providers: ['internal', 'google-analytics'],
    },

    // When a user cicks the 'Sign Up' tab on the Sign Up/Login modal
    SIGN_UP_WORKSHEET: {
        name: 'sign-up-worksheet',
        providers: ['internal', 'google-analytics'],
    },

    // When a user clicks the 'Sign Up' button in the top navigation bar
    SIGN_UP_WORKSHEET_TOOLBAR: {
        name: 'sign-up-worksheet-toolbar',
        providers: ['internal', 'google-analytics'],
    },

    // When a user has signed up
    SIGN_UP_SUCCEEDED: {
        name: 'signup_succeeded',
        providers: ['internal', 'google-analytics'],
    },

    // When a user cancels their subscription
    SUBSCRIPTION_CANCELLED: {
        name: 'subscription-cancelled',
        providers: ['internal', 'google-analytics'],
    },

    // When a user selects a role e.g. 'Teacher'
    USER_ROLE_SELECTED: {
        name: 'user-role-selected',
        providers: ['internal', 'google-analytics'],
    },

    GO_FONT_PREMIUM: {
        name: 'go_font_premium',
        providers: ['statsig'],
    },

    GO_PREMIUM: {
        name: 'go_premium',
        providers: ['statsig'],
    },

    NEW_USER_VISIT: {
        name: 'new-user-visit',
        providers: ['statsig'],
    },

    USER_VISIT: {
        name: 'user-visit',
        providers: ['statsig'],
    },

    AUTHENTICATE: {
        name: 'authenticate',
        providers: ['statsig'],
    },

    AUTHENTICATION_ATTEMPTED: {
        name: 'authentication-attempted',
        providers: ['statsig'],
    },

    AUTHENTICATION_SUCCEEDED: {
        name: 'authentication-succeeded',
        providers: ['statsig'],
    },

    PUBLISH_DOCUMENT: {
        name: 'publish-document',
        providers: ['statsig'],
    },

    PUBLISH_FIRST_DOCUMENT: {
        name: 'publish-first-document',
        providers: ['statsig'],
    },

    PUBLISH_BUTTON_CLICK: {
        name: 'publish-button-click',
        providers: ['statsig', 'internal', 'google-analytics'],
    },

    PUBLISH_SUCCESS: {
        name: 'publish-success',
        providers: ['statsig'],
    },

    REPUBLISH_SUCCESS: {
        name: 'republish-success',
        providers: ['statsig'],
    },

    CREATE_DOCUMENT: {
        name: 'create-document',
        providers: ['statsig'],
    },

    NEW_DOCUMENT: {
        name: 'new-document',
        providers: ['statsig'],
    },

    NEW_BINGO_DOCUMENT: {
        name: 'new-bingo-document',
        providers: ['statsig'],
    },

    NEW_FLASHCARD_DOCUMENT: {
        name: 'new-flashcard-document',
        providers: ['statsig'],
    },

    NEW_WORKSHEET_DOCUMENT: {
        name: 'new-worksheet-document',
        providers: ['statsig'],
    },

    PERSIST: {
        name: 'persist',
        providers: ['statsig'],
    },

    NAVIGATE: {
        name: 'navigate',
        providers: ['statsig'],
    },

    NAVIGATE_FOR_PAYMENT: {
        name: 'navigate-for-payment',
        providers: ['statsig'],
    },

    CHOOSE_PLAN: {
        name: 'choose-plan',
        providers: ['statsig'],
    },

    PAYMENT_SUBMISSION: {
        name: 'payment-submission',
        providers: ['statsig'],
    },

    PAYMENT_SUCCESS: {
        name: 'payment-success',
        providers: ['statsig'],
    },

    FREE_SUBSCRIPTION_SIGNUP: {
        name: 'free-subscription-signup',
        providers: ['statsig'],
    },

    NEW_USER_SIGNUP: {
        name: 'new-user-signup',
        providers: ['statsig'],
    },

    // When a user sees the Log In overlay
    SEEN_EMAIL_OVERLAY: {
        name: 'seen-email-overlay',
        providers: ['internal', 'google-analytics', 'statsig'],
    },

    // When a user sees the Sign Up overlay
    SEEN_SIGNUP_OVERLAY: {
        name: 'seen-signup-overlay',
        providers: ['internal', 'google-analytics', 'statsig'],
    },

    ONBOARDING_PROCESS: {
        name: 'onboarding-process-step',
        providers: ['statsig'],
    },

    HEADER_STYLES_BTN_CLICK: {
        name: 'header-styles-toggle-button-click',
        providers: ['statsig'],
    },
}

export type EventName = (typeof UserEvent)[keyof typeof UserEvent]['name']

// Helper to get providers for an event
export const getEventProviders = (eventName: string): EventDefinition['providers'] => {
    const event = Object.values(UserEvent).find((event): event is EventDefinition => event.name === eventName)
    return event?.providers ?? ([] as EventDefinition['providers'])
}

export default Object.freeze(UserEvent)

/****************************************
 * Dynamic event names
 ****************************************/

/**
 * When a user shares a published document to facebook, twitter pinterest
 * - ${service}_share
 */

/**
 * Not used
 * - 'button interface - ' + key
 * - go-premium-print-panel-published|not-logged-in
 * - go-premium-publish-panel-published
 * - paywall|not-logged-in
 * - 'set_document_type-' + option.key
 * - 'upgrade-plan-' + plan.interval + '-' + plan.id
 */

/**
 * When selecting a free trial from the plan selector in the checkout modal
 * - 'free-trial-' + plan.interval + '-' + plan.id
 */

/**
 * When selecting a plan from the plan selector in the checkout modal
 * - `buy-now-${plan.interval}-${plan.id}`
 */

/****************************************
 * Needs to be added
 ****************************************/
// When a non-premium, authenticated user clicks the Go Premium button from the tooltip when attempting to remove the logo from a published worksheet
// When a non-premium user clicks 'Add New Folder' and then 'Go Premium'
// When an unauthenticated user clicks 'Go Premium' from a generic premium marker icon like Box or Circle option in the Fill in the Blank widget
// When a non-premium, unauthenticated user clicks the Go Premium button from the tooltip when attempting to remove the logo from a published worksheet
// When an authenticated user clicks 'Go Premium' from a generic premium marker icon like Box or Circle option in the Fill in the Blank widget
