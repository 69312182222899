import UserApi from '../../apis/UserApi'
import apiClient from '../../apis/apiClient'
import UserEvent from '../../objects/UserEvent'
import { clearStorage } from '../../common/helpers'
import { getBrowserUuid, getStatsigUserPayload, isEligibleNewUser } from '../../mixins/StatsigHelper'

export const namespaced = true

export const state = {
    user: {
        id: null,
        email: null,
        ink_saver: 0,
        white_label: 0,
        has_plan: 0,
        is_lifetime_member: 0,
        subscription_plan: '',
        occupation: null,
        trialed: null,
        onGenericTrial: null,
        purchased: null,
        request_no_email: null,
        first_published_at: null,
        last_published_at: null,
        is_admin: false,
        historical_published_documents_count: 0,
    },
    invoices: [],
    invoices_loading: false,
    payments: [],
    payments_loading: false,
    ab_tests: {},
    is_loading: false,
    hasBeenCalled: false,
    messageListener: false,
}

export const mutations = {
    SET_VALUE(state, values) {
        Object.keys(values).forEach((value) => {
            state[value] = values[value]
        })
    },
    SET_USER_ID(state, id) {
        state.user.id = id
    },
    SET_USER_EMAIL(state, email) {
        state.user.email = email
    },
    SET_USER_INK_SAVER(state, ink_saver) {
        state.user.ink_saver = ink_saver
    },
    SET_USER_WHITE_LABEL(state, white_label) {
        state.user.white_label = white_label
    },
    SET_USER_OCCUPATION(state, occupation) {
        state.user.occupation = occupation
    },
    SET_USER_IS_ADMIN(state, is_admin) {
        state.user.is_admin = is_admin
    },
    SET_USER_HAS_PLAN(state, has_plan) {
        state.user.has_plan = has_plan
    },
    SET_USER_IS_LIFETIME_MEMBER(state, is_lifetime_member) {
        state.user.is_lifetime_member = is_lifetime_member
    },
    SET_USER_SUBSCRIPTION_PLAN(state, subscription_plan) {
        state.user.subscription_plan = subscription_plan
    },
    SET_IS_LOADING(state, is_loading) {
        state.is_loading = is_loading
    },
    SET_AB_TESTS(state, ab_tests) {
        // loop through tests and store them individually
        Object.keys(ab_tests).forEach((test) => {
            state.ab_tests[test] = ab_tests[test]
        })
    },
    SET_USER_trialed(state, trialed) {
        state.user.trialed = trialed
    },
    SET_USER_DOCUMENT_PURCHASED(state, documents_purchased) {
        state.user.documents_purchased = documents_purchased
    },
    SET_USER_EMAILS(state, emails) {
        state.user.request_no_email = emails
    },
    SET_USER_FIRST_PUBLISHED_AT(state, timestamp) {
        state.user.first_published_at = timestamp
    },
    SET_USER_LAST_PUBLISHED_AT(state, timestamp) {
        state.user.last_published_at = timestamp
    },
    SET_USER_GENERIC_TRIAL(state, onGenericTrial) {
        state.user.onGenericTrial = onGenericTrial
    },
    SET_USER_HISTORICAL_PUBLISHED_DOCUMENTS_COUNT(state, count) {
        state.user.historical_published_documents_count = count
    },
    SET_HAS_BEEN_CALLED(state, val) {
        state.hasBeenCalled = val
    },
    SET_MESSAGE_LISTENER(state, val) {
        state.messageListener = val
    },
}

export const actions = {
    async initialize({ dispatch, rootState }) {
        // set AB tests
        if (window.abtests) {
            dispatch('setAbTests', window.abtests)
        }

        if (!getters.isLoggedIn) dispatch('checkUser')

        // If a user was logged in when this page was loaded dispatch setUser
        if (window.user?.id) {
            dispatch('setUser', window.user)
            dispatch('setLocalStorageUser')
        } else {
            //if this page was loaded and the user was logged out, clear localStorage
            dispatch('clearLocalStorageUser')
        }

        // When local storage changes, find out what needs to update.
        window.onstorage = (event) => {
            // This check stops the event from firing when other keys are updated like a the current working document, which
            // causes a chain of functions to end up DDOSing the server.
            if (event.key !== 'auth_response') return

            // Parse auth_response key from local storage
            let auth_response = JSON.parse(window.localStorage.getItem('auth_response'))
            if (auth_response) {
                // User is logged in from a different tab
                dispatch('setUser', auth_response)
            } else {
                // User is logged out from a different tab
                dispatch('setUser', {
                    id: null,
                    email: null,
                    ink_saver: 0,
                    white_label: 0,
                    has_plan: 0,
                    occupation: null,
                })
            }
        }
    },
    setLocalStorageUser({ state }) {
        localStorage.setItem('auth_response', JSON.stringify(state.user))
    },
    clearLocalStorageUser() {
        localStorage.removeItem('auth_response')
    },
    setValue({ commit }, value) {
        commit('SET_VALUE', value)
    },
    initSocialLogin({}, provider, authType = 'login') {
        var tz = Intl.DateTimeFormat().resolvedOptions().timeZone
        window.authType = authType
        window.open('/oauth/' + provider + '?timezone=' + tz, '_blank', 'height=600,width=400')
    },
    async processAuthResponse({ dispatch, state }, response) {
        //do we have pre-existing updates to push?
        let userUpdates = {}

        // has the user updated their ink_saver before logging in?
        if (Number(state.user.ink_saver) && !Number(response.data.data.ink_saver)) {
            userUpdates['ink_saver'] = Number(state.user.ink_saver)
        }
        // has the user updated their occupation before logging in?
        if (state.user.occupation && state.user.occupation != response.data.data.occupation) {
            userUpdates['occupation'] = state.user.occupation
        }
        // set the current user and AB tests according to the data received

        await dispatch('setUser', response.data.data)

        //store the authenticated user in localStorage auth_response
        await dispatch('setLocalStorageUser')

        // we have user updates to take into account
        if (Object.keys(userUpdates).length) {
            await dispatch('updateUser', userUpdates)
        }

        let userResponse = response.data.data
        if (!userResponse.custom_ab_stable_id && isEligibleNewUser(userResponse)) {
            dispatch('updateUserInitialBrowser', { id: userResponse.id })
        }

        document.dispatchEvent(new CustomEvent('logIn'))

        // update statsig user
        const statSigUser = await getStatsigUserPayload(userResponse)

        try {
            const updated = await window.statsig?.updateUser(statSigUser)

            if (updated) {
                dispatch('abtests/loadAbTests', null, { root: true })
                dispatch('abtests/loadAbGroup', null, { root: true })
                dispatch(
                    'abtests/logStatsigEvent',
                    {
                        event: UserEvent.AUTHENTICATION_SUCCEEDED,
                        value: response.data.data.uuid,
                        payload: {
                            authMethod: response.authMethod,
                            authType: response.signIn ? 'login' : 'create account',
                            userSignedUpToday:
                                new Date(userResponse.created_at) > new Date(new Date().getTime() - 24 * 60 * 60 * 1000),
                        },
                    },
                    { root: true },
                )
            }
        } catch (error) {
            if (error.response) {
                console.log(error.response.data)
                console.log(error.response.status)
                console.log(error.response.headers)
            } else if (error.request) {
                console.log(error.request)
            } else {
                console.log('Error', error.message)
            }
        }
    },
    forgotPassword({ commit, dispatch, state }, { email }) {
        commit('SET_IS_LOADING', true)
        return new Promise((resolve, reject) => {
            return apiClient
                .post('/password/email', {
                    email: email,
                })
                .then((response) => {
                    commit('SET_IS_LOADING', false)
                    resolve({
                        message: 'A password reset link has been emailed to you.',
                    })
                })
                .catch((error) => {
                    commit('SET_IS_LOADING', false)
                    reject(error.response.data)
                })
        })
    },
    async checkUser({ commit, dispatch, rootGetters }) {
        try {
            const response = await apiClient.get(`/sso/is-auth/`)

            dispatch(
                'abtests/logStatsigEvent',
                {
                    event: UserEvent.AUTHENTICATION_ATTEMPTED,
                    value: this.documentType,
                    payload: {
                        authMethod: 'manual',
                        authType: 'login',
                    },
                },
                { root: true },
            )

            if (!response.data) throw new Error('User not logged in')

            commit('SET_IS_LOADING', false)

            clearStorage(rootGetters['document/documentKey'], rootGetters['document/storedDocument'])

            await dispatch('processAuthResponse', {
                ...response,
                signIn: true,
                authMethod: 'manual',
            })

            return response
        } catch {
            window.user = null
            dispatch('clearLocalStorageUser')
            dispatch('setUser', {
                id: null,
                email: null,
                ink_saver: 0,
                white_label: 0,
                has_plan: 0,
                occupation: null,
            })
            commit('SET_IS_LOADING', false)
        }
    },
    // TODO: See if we can remove this function
    logIn({ commit, dispatch, rootGetters }, user) {
        return new Promise((resolve, reject) => {
            dispatch(
                'abtests/logStatsigEvent',
                {
                    event: UserEvent.AUTHENTICATION_ATTEMPTED,
                    value: user.email,
                    payload: {
                        authMethod: 'manual',
                        authType: 'login',
                    },
                },
                { root: true },
            )

            clearStorage(rootGetters['document/documentKey'], rootGetters['document/storedDocument'])
            dispatch('storeRecentSearches').finally((response) => {
                clearStorage(rootGetters['document/documentKey'], rootGetters['document/storedDocument'])
            })
            resolve()
        })
    },
    // TODO: See if we can remove this function
    googleLogIn({ commit, dispatch, rootGetters }, { email, google_id }) {
        commit('SET_IS_LOADING', true)
        return new Promise((resolve, reject) => {
            dispatch(
                'abtests/logStatsigEvent',
                {
                    event: UserEvent.AUTHENTICATION_ATTEMPTED,
                    value: email,
                    payload: {
                        authMethod: 'google',
                        authType: 'login',
                    },
                },
                { root: true },
            )
            apiClient
                .post('/account/google_login', {
                    email: email,
                    googleId: google_id,
                    remember_me: 0,
                    referrer: window.referer,
                    login_page: window.location.pathname,
                    landing_page: window.landing_page,
                    user_agent: navigator.userAgent,
                    viewport_width: window.screen.width,
                    viewport_height: window.screen.height,
                    screen_width: window.outerWidth,
                    screen_height: window.outerHeight,
                })
                .then((response) => {
                    commit('SET_IS_LOADING', false)

                    dispatch('storeRecentSearches').finally((response) => {
                        clearStorage(rootGetters['document/documentKey'], rootGetters['document/storedDocument'])
                    })

                    dispatch('processAuthResponse', {
                        ...response,
                        signIn: true,
                        authMethod: 'google',
                    })

                    resolve()
                })
                .catch((error) => {
                    commit('SET_IS_LOADING', false)
                    reject(error)
                })
        })
    },
    signUp({ commit, dispatch, rootGetters }, { email, password, remember, timezone }) {
        commit('SET_IS_LOADING', true)
        return new Promise((resolve, reject) => {
            dispatch(
                'abtests/logStatsigEvent',
                {
                    event: UserEvent.AUTHENTICATION_ATTEMPTED,
                    value: email,
                    payload: {
                        authMethod: 'manual',
                        authType: 'create account',
                    },
                },
                { root: true },
            )
            apiClient
                .post('/account', {
                    email: email,
                    password: password,
                    remember_me: remember,
                    timezone: timezone,
                })
                .then((response) => {
                    commit('SET_IS_LOADING', false)

                    dispatch('storeRecentSearches')
                    resolve()
                })
                .catch((error) => {
                    commit('SET_IS_LOADING', false)
                    reject(error)
                })
        })
    },
    setAbTests({ commit }, tests) {
        commit('SET_AB_TESTS', tests)
    },
    setWhiteLabel({ commit }, value) {
        commit('SET_USER_WHITE_LABEL', value)
    },
    setUser({ commit, dispatch, getters }, user) {
        window.user = user
        if (typeof user.id != 'undefined') {
            commit('SET_USER_ID', user.id)
        }
        if (typeof user.email != 'undefined') {
            commit('SET_USER_EMAIL', user.email)
        }
        if (typeof user.ink_saver != 'undefined') {
            commit('SET_USER_INK_SAVER', user.ink_saver)
        }
        if (typeof user.white_label != 'undefined') {
            commit('SET_USER_WHITE_LABEL', user.white_label)
        }
        if (typeof user.has_plan != 'undefined') {
            commit('SET_USER_HAS_PLAN', user.has_plan)
        }
        if (typeof user.is_lifetime_member != 'undefined') {
            commit('SET_USER_IS_LIFETIME_MEMBER', user.is_lifetime_member)
        }
        if (typeof user.subscription_plan != 'undefined') {
            commit('SET_USER_SUBSCRIPTION_PLAN', user.subscription_plan)
        }
        if (typeof user.occupation != 'undefined') {
            commit('SET_USER_OCCUPATION', user.occupation)
        }
        if (typeof user.is_admin != 'undefined') {
            commit('SET_USER_IS_ADMIN', user.is_admin)
        }
        if (typeof user.trialed != 'undefined') {
            commit('SET_USER_trialed', user.trialed)
        }
        if (typeof user.documents_purchased != 'undefined') {
            commit('SET_USER_DOCUMENT_PURCHASED', user.documents_purchased)
        }
        if (typeof user.request_no_email != 'undefined') {
            commit('SET_USER_EMAILS', user.request_no_email)
        }
        if (typeof user.first_published_at != 'undefined') {
            commit('SET_USER_FIRST_PUBLISHED_AT', user.first_published_at)
        }
        if (typeof user.last_published_at != 'undefined') {
            commit('SET_USER_LAST_PUBLISHED_AT', user.last_published_at)
        }
        if (typeof user.onGenericTrial != 'undefined') {
            commit('SET_USER_GENERIC_TRIAL', user.onGenericTrial)
        }
        if (typeof user.historical_published_documents_count != 'undefined') {
            commit('SET_USER_HISTORICAL_PUBLISHED_DOCUMENTS_COUNT', user.historical_published_documents_count)
        }

        if (getters.isLoggedIn) {
            //we have a user
            dispatch('subscription/getAvailablePlans', null, { root: true })
            dispatch('subscription/getSubscription', null, { root: true })
            dispatch('cards/getCards', null, { root: true })
        }
    },
    async getPayments({ commit, dispatch }) {
        commit('SET_VALUE', { payments_loading: true })
        try {
            const result = await apiClient.get('/account/user/payments')
            dispatch('setValue', { payments: result.data })
            localStorage.setItem('user.payments', result.data)
        } catch (error) {
            console.error('There was an error obtaining the payment information:', error)
            throw error
        } finally {
            commit('SET_VALUE', { payments_loading: false })
        }
    },
    updateUser({ commit, state, dispatch }, user) {
        //make a backup to roll back from in the event of an error
        let existingUser = JSON.parse(JSON.stringify(state.user))

        // Set our local store for the user and then attempt the update.
        // This will allow non-logged-in users to set their ink_saver setting
        dispatch('setUser', user)

        return new Promise((resolve, reject) => {
            if (state.user.id) {
                //if we're logged in try and save remotely
                commit('SET_IS_LOADING', true)
                UserApi.update(state.user.id, user)
                    .then((response) => {
                        commit('SET_IS_LOADING', false)
                        resolve()
                        // do nothing from here. We're just updating for later.
                    })
                    .catch((error) => {
                        //error? roll back the user edits
                        dispatch('setUser', existingUser)

                        //end is loading
                        commit('SET_IS_LOADING', false)
                        reject(error)
                    })
            } else {
                resolve()
            }
        })
    },
    updateOtherUser({ commit, state, dispatch }, payload) {
        return new Promise((resolve, reject) => {
            commit('SET_IS_LOADING', true)

            UserApi.update(payload.id, payload)
                .then((response) => {
                    commit('SET_IS_LOADING', false)
                    resolve()
                })
                .catch((error) => {
                    commit('SET_IS_LOADING', false)
                    reject(error)
                })
        })
    },
    async updateUserInitialBrowser({ commit, state, dispatch }, payload) {
        payload.initial_browser_uuid = await getBrowserUuid()

        return new Promise((resolve, reject) => {
            commit('SET_IS_LOADING', true)

            UserApi.updateBrowser(payload.id, payload)
                .then((response) => {
                    commit('SET_IS_LOADING', false)
                    resolve()
                })
                .catch((error) => {
                    commit('SET_IS_LOADING', false)
                    reject(error)
                })
        })
    },
    adminResetPassword({ commit, dispatch, state }, { email }) {
        commit('SET_IS_LOADING', true)
        return new Promise((resolve, reject) => {
            return apiClient
                .post('/admin/password/email', {
                    email: email,
                })
                .then((response) => {
                    commit('SET_IS_LOADING', false)
                    resolve({
                        message: 'A password reset link has been emailed to you.',
                    })
                })
                .catch((error) => {
                    commit('SET_IS_LOADING', false)
                    reject(error.response.data)
                })
        })
    },
    setSubscriptionPlan({ commit }, subscription_plan) {
        commit('SET_USER_SUBSCRIPTION_PLAN', subscription_plan)
    },
    storeRecentSearches({ commit, dispatch, state }) {
        //store user's recent searches if any
        let recentSearches = JSON.parse(sessionStorage.getItem('recent_searches'))

        if (recentSearches?.length) {
            return new Promise((resolve, reject) => {
                return apiClient
                    .post('/search/recent', { recent_searches: recentSearches })
                    .then((response) => {
                        dispatch('browse/getRecentSearches', null, { root: true })
                        sessionStorage.removeItem('recent_searches')
                        localStorage.removeItem('recent_searches')
                        resolve()
                    })
                    .catch((error) => {
                        reject(error.response.data)
                    })
            })
        }
    },
    syncStripeSubscription({ commit, dispatch, state }, { id }) {
        commit('SET_IS_LOADING', true)
        return new Promise((resolve, reject) => {
            return apiClient
                .post('/admin/stripe/sync', {
                    id: id,
                })
                .then((response) => {
                    commit('SET_IS_LOADING', false)
                    resolve({
                        message: 'User subscriptions successfully synchronized',
                    })
                })
                .catch((error) => {
                    commit('SET_IS_LOADING', false)
                    reject(error.response.data)
                })
        })
    },
    setHasBeenCalled({ commit }, val) {
        commit('SET_HAS_BEEN_CALLED', val)
    },
    setMessageListener({ commit }, val) {
        commit('SET_MESSAGE_LISTENER', val)
    },
}

export const getters = {
    isLoggedIn: (state) => {
        return state.user.id ? true : false
    },
    userId: (state) => {
        return state.user.id
    },
    hasPlan: (state) => {
        return state.user.has_plan ? true : false
    },
    hasTrialed: (state) => {
        return state.user.trialed
    },
    neverHadTrial: (state) => {
        return !state.user.has_plan && !state.user.trialed && state.user.subscription_plan === 'Free' ? true : false
    },
    onboardingComplete: (state) => {
        let localStep = localStorage.getItem(UserEvent.ONBOARDING_PROCESS.name)
        let onboardingSurveyDone = state.user && state.user.occupation != null && state.user.request_no_email != null
        return state.user.has_plan ||
            state.user.trialed ||
            state.user.subscription_plan !== 'Free' ||
            onboardingSurveyDone ||
            (localStep != null && parseInt(localStep) >= 3)
            ? true
            : false
    },
    isOnFreePlan: (state) => {
        return state.user.subscription_plan.toLowerCase() === 'free'
    },
    isLifetimeMember: (state) => {
        return state.user.is_lifetime_member ? true : false
    },
    getPayments: (state) => {
        return state.payments
    },
    isAdmin: (state) => Boolean(state.user.is_admin),
    hasBeenCalled: (state) => state.hasBeenCalled,
    messageListener: (state) => state.messageListener,
}
